import React, { useState, useEffect } from "react";
import axios from "../axios";
import ContentLoader, { Facebook, Instagram } from 'react-content-loader';
function Team() {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [show, setShow] = useState(false);
  const [selectedData, setSelectedData] = useState({});

  const fetchTeam = () => {
    setLoading(true);
    //return axios.get('/teams').then((res) => setData(res.data));
    axios.get('/teams').then((res) => {
      setLoading(false);
      //console.log(res);
      setData(res.data);
    });

  };

  useEffect(() => {
    document.title = 'Teams';
    fetchTeam();
  }, []);

  const hanldeClick = (selectedRec) => {
    setSelectedData(selectedRec);
    setShow(true);
  };

  const hideModal = () => {
    setShow(false);
  };

  return (
    <>
      {loading ? (
        <main id="main" data-aos="fade-up" className="scrolled-offset mnheight">
          <Facebook speed={3} />
        </main>
      ) : (
        <main id="main" data-aos="fade-up" className="scrolled-offset mnheight">
          <section id="team" className="team section-bg">
            <div className="container" data-aos="fade-up">
              <div className="section-title">
                <h3>Our <span>Team</span></h3>
              </div>

              <div className="row">

                {data.map((dataObj, index) => {
                  return (

                    <div className="col-lg-3 col-md-6 d-flex align-items-stretch">
                      
                      <div className="member">
                        <div className="member-img" >
                          <img src={dataObj.img} onClick={() => hanldeClick(dataObj)} className="img-fluid" alt="team member" data-bs-toggle="modal" data-bs-target="#staticBackdrop-3" />
                          <div className="social">
                          {dataObj.linkedin.length ? <a href={dataObj.linkedin}><i className="bi bi-linkedin"></i></a> : ''}
                          {dataObj.email.length ? <a href={'mailto:'+dataObj.email}><i className="bi bi-envelope"></i></a> : ''}
                          </div>
                        </div> 
                        <div className="member-info">
                          <h4>{dataObj.title}</h4>
                          <span>{dataObj.profile}</span>
                          <p for="">{dataObj.company_name}</p>
                        </div>
                      </div>

                      {show && <Modal details={selectedData} handleClose={hideModal} />}

                    </div>

                  );
                })}


              </div>

            </div>
          </section>
        </main>
      )}

    </>
  );
}
export default Team;
const Modal = ({ handleClose, details }) => {
  return (
    <div className="modal display-block">
      <section className="modal-main">

        <div className="modal-content">

          <div className="modal-body">
            <div className="row">
              <div className="col-lg-6">
                <img src={details.img} className="img-fluid" alt="team member" />
              </div>
              <div className="col-lg-6">
                <div className="member-info">
                  <h4>{details.title}</h4>
                  <span>{details.profile}</span>
                  <p for="">{details.company_name}</p>
                  <p><div dangerouslySetInnerHTML={{ __html: details.post_content }} /></p>
                </div>
                <div className="modal-social">
                  {details.linkedin.length ? <a href={details.linkedin}><i className="bi bi-linkedin"></i></a> : ''}
                  {details.email.length ? <a href={'mailto:'+details.email}><i className="bi bi-envelope"></i></a> : ''}
                          
                </div>
              </div>
            </div>
          </div>
          <div id="time-modal-footer" className="modal-footer">
            <button type="button" onClick={handleClose} className="btn btn-default" data-dismiss="modal">Close</button>
          </div>
        </div>

      </section>
    </div>
  );
};

