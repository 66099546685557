import React, { useState, useEffect } from "react";
import axios from "../axios";
import ContentLoader, { Facebook, Instagram } from 'react-content-loader';

function Lifestyle() {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [show, setShow] = useState(false);
  const [selectedData, setSelectedData] = useState({});
  const [defaultData, setdefaultData] = useState();
  const [active, setActive] = useState(null);
  const fetchInfo = () => {
    setLoading(true);
    //return axios.get('/teams').then((res) => setData(res.data));
    axios.get('/lifestyle').then((res) => {
      setLoading(false);
      //console.log(res);
      
      setdefaultData(res.data[0]);
      setActive(res.data[0].id);
      setData(res.data);
    });

  };

  useEffect(() => {
    document.title = 'Lifestyle';
    fetchInfo();
  }, []);

  const hanldeClick = (selectedRec) => {
    setActive(selectedRec.id);
    setSelectedData(selectedRec);
    setShow(true);
    setdefaultData();
  };

  return (
    <>

      {loading ? (
        <main id="main" data-aos="fade-up" className="scrolled-offset mnheight">
          <Facebook speed={3} />
        </main>
      ) : (
        <main id="main" data-aos="fade-up" className="scrolled-offset mnheight">
          <section className="inner-page">
            <div className="container">
              <div className="row p-3">
                <div className="col-lg-12 col-md-12 col-sm-12">
                  <h2 className="headline">Lifestyle</h2>
                </div>
              </div>

        <div className="row justify-content-center">
                {data.map((dataObj) => {
                          return (
      
                          <div className="col-lg-3 col-md-6 col-sm-12 d-flex align-items-center justify-content-center mb-2">
                            <div className="member">
                            <div  className={`brand-img ${active == dataObj.id && 'active'}`}>
                                <img src={dataObj.website_logo} onClick={() => hanldeClick(dataObj)} className="img-fluid" alt="brand" style={{width:'200px',height:'200px'}}/>
                              </div>
                            </div>
                          </div>

                          );
                  })}
        </div>

 

        {defaultData ? <BrandDetail details={defaultData}/> : <BrandDetail details={selectedData} />}
 
            </div>
          </section>
        </main>
      )}

    </>
  );
}

export default Lifestyle;

const BrandDetail = ({ details }) => {
  return (
    <div className="row justify-content-center pt-5">
    <div className="col-lg-10">
      <div className="card card-body shadow">
        <div className="row justify-content-center">
 
          <div className="col-lg-12 col-md-6 col-sm-12">
            <div className="col-sm-12">
                <div className="row justify-content-between">
                  <div className="col-sm-4 brand-logo">
                    <img src={details.website_logo_sec} alt="horse illustrated" className="img-fluid img-responsive" style={{width: '150px'}} />
                  </div>
                  <div className="col-sm-2">
                    <ul className="" style={{listStyle: 'none',display: 'flex', alignContent: 'center', justifyContent: 'space-evenly',marginTop: '20px'}}>
                      <li><a href={details.facebook} target="_blank" rel="noopener noreferrer"><img src="https://egmediamailimg.s3.ap-south-1.amazonaws.com/web-storage/EG%20Media_corp_website/images/brands/FB.png" alt="horse illustrated" className="img-fluid img-responsive" width="32"  /></a></li>
                      <li><a href={details.instagram} target="_blank" rel="noopener noreferrer"><img src="https://egmediamailimg.s3.ap-south-1.amazonaws.com/web-storage/EG%20Media_corp_website/images/brands/Insta.png" alt="horse illustrated" className="img-fluid img-responsive" width="32"  /></a></li>
                      
                    </ul>
                  </div>
                </div>
            </div>
            <div className="desc-text">
              <p className="m-3 mt-3"><div dangerouslySetInnerHTML={{ __html: details.post_content }} /></p>
            </div>
            <div className="btn-rm">
              <a href={details.website_url}className="btn btn-sm btn-custom shadow-sm m-3 mt-1" style={{backgroundColor: '#306899', color: '#ffffff'}}>Visit Website</a>
            </div>
          </div>
        </div>
      </div>

      
    </div>
  </div>
  );
};