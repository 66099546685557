import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import { Button } from "react-bootstrap";

function Footer() {
  const [showTopBtn, setShowTopBtn] = useState(false);
  useEffect(() => {
    window.addEventListener("scroll", () => {
        if (window.scrollY > 400) {
            setShowTopBtn(true);
        } else {
            setShowTopBtn(false);
        }
    });
}, []);

const goToTop = () => {
  window.scrollTo({
      top: 0,
      behavior: "smooth",
  });
};

  return (
    <>
<footer id="footer">
    <div className="footer-top">
      <div className="container">
        <div className="row justify-content-between">

          <div className="col-lg-4 col-md-6 footer-contact">
            <NavLink to="/">
             <img src="https://egmediamailimg.s3.ap-south-1.amazonaws.com/web-storage/EG%20Media_corp_website/Logo/EG-Logo.png" style={{ width:'100px'}} alt="" />
            </NavLink>
            <p>
            <NavLink className="ft" to="/privacy-policy">Privacy Policy</NavLink> | <NavLink className="ft" to="/terms-of-service">Terms &amp; Conditions</NavLink>
             <br/>
              Copyright &copy; 2023 EG Media Invetments LLC. <br/>
              All rights reserved
            </p>
          </div>
     

          <div className="col-lg-4 col-md-6 footer-links">
            <div className="btn-footer">
              <NavLink className="btn btn-md" to="/contact"><span> <i className="bi bi-envelope"></i> </span> Partner with us</NavLink>
            </div>
            <h3 className="footer-heading">See What Where Up To</h3>
            <div className="social-links mt-3">
              <a href="https://www.instagram.com/" className="instagram"><i className="bi bi-instagram"></i></a>
              <a href="https://www.facebook.com/" className="facebook"><i className="bi bi-facebook"></i></a>
              <a href="https://twitter.com/" className="twitter"><i className="bi bi-twitter"></i></a>
              <a href="https://in.linkedin.com/" className="linkedin"><i className="bi bi-linkedin"></i></a>
            </div>
          </div>

        </div>
      </div>
    </div>
  </footer>
 
  { showTopBtn ?   <Button className="back-to-top d-flex align-items-center justify-content-center active" onClick={goToTop}><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-up" viewBox="0 0 16 16">
  <path fill-rule="evenodd" d="M8 15a.5.5 0 0 0 .5-.5V2.707l3.146 3.147a.5.5 0 0 0 .708-.708l-4-4a.5.5 0 0 0-.708 0l-4 4a.5.5 0 1 0 .708.708L7.5 2.707V14.5a.5.5 0 0 0 .5.5z"/>
</svg>
</Button> : ''}
  </>
  );
}

export default Footer;
