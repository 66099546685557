import React, { useState, useEffect } from "react";
import axios from "../axios";
import Carousel from 'react-bootstrap/Carousel';
import ContentLoader, { Facebook, Instagram } from 'react-content-loader';

import Slider from "./Slider";
import OurExperts from "./OurExperts";
//https://codepen.io/localhoster/pen/eYQWEjY
function Home() {
  const [loading, setLoading] = useState(false);

  const [data, setData] = useState([]);
  const [expertise, setExpertise] = useState([]);
  const [mission, setMission] = useState([]);

  const fetchSlider = () => {
    setLoading(true);
    //return axios.get('/teams').then((res) => setData(res.data));
    axios.get('/slider').then((res) => {
      setLoading(false);
      setData(res.data);
    });

  };


  useEffect(() => {
    document.title = 'Home';
    fetchSlider();

  }, []);

  return (

    <>
      {loading ? (
        <main id="main" data-aos="fade-up" className="scrolled-offset mnheight">
          <Facebook speed={3} />
        </main>
      ) : ''}
      <main id="main" data-aos="fade-up" className="scrolled-offset mnheight">
        <section id="" className="p-0">
          <div className="row">
            <div className="col-lg-12">
 
                {data.map((dataObj, index) => {
                  return (


                      <img className="d-block w-100" src={dataObj.img} alt="slider" />


                  );

                })}

         
            </div>
          </div>

 
        </section>

 
      </main >
    </>
  );
}

export default Home;
