import React, { useState, useEffect } from "react";
import axios from "../axios";
import ContentLoader, { Facebook, Instagram } from 'react-content-loader';
function About() {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const fetchAbout = () => {
    setLoading(true);
    //return axios.get('/teams').then((res) => setData(res.data));
    axios.get('/about').then((res) => {
      //console.log(res);
      setLoading(false);
      setData(res.data);
    });

  };

  useEffect(() => {
    document.title = 'About';
    fetchAbout();
  }, []);

  return (

 <>
{loading ? (
        <main id="main" data-aos="fade-up" className="scrolled-offset mnheight">
        <Facebook speed={3} />
      </main>
      ) : (
        <main id="main" data-aos="fade-up" className="scrolled-offset mnheight">
        <section id="about" className="about">
        <div className="container" data-aos="fade-up">
          <div className="section-title">
            <h3>{data.title}</h3>
          </div>
  
          <div className="row">
            <div className="col-lg-12">
              <p><div dangerouslySetInnerHTML={{__html: data.post_content}}/> </p>
            </div>
          </div>
  
        </div>
      </section>
      </main>
        )}
</>

  );
}

export default About;
