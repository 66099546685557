import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import axios from "../axios";
import { useForm } from "react-hook-form";
import ContentLoader, { Facebook, Instagram } from 'react-content-loader';
function OurExpertDetail() {
  const [loading, setLoading] = useState(true);
  // Get ID from URL
  const params = useParams();
  const jobids = params.id;
  const [posts, setPosts] = useState([]);

  useEffect(() => {
    setLoading(true);
    document.title = 'Our Expert';
    axios.get('/expert_detail/?post_id=' + params.id).then((res) => {
      setPosts(res.data);
    });

    setLoading(false);

  }, []);


  return (

    <div>

      {loading ? (
        <main id="main" data-aos="fade-up" className="scrolled-offset mnheight">
          <Facebook speed={3} />
        </main>
      ) : (
        <main id="main" className="scrolled-offset mnheight">
          <section className="inner-page pt-0">

            <section id="contact" className="contact">
              <div className="container">
                <div className="row justify-content-center">
                  <div className="col-lg-10 col-md-12 col-sm-12">


                    <div className="row">
                      <div className="col-lg-6">
                        <img src={posts.img} className="img-fluid" alt="Expert" />
                      </div>
                      <div className="col-lg-6">
                        <div className="member-info">
                          <h4>{posts.title}</h4>
                          <span>Editor in Chief</span>
                          <p for="">{posts.company_name}</p>
                          <p><div dangerouslySetInnerHTML={{ __html: posts.post_content }} /></p>
                        </div>

                      </div>
                    </div>

                  </div>
                </div>



              </div>
            </section>

          </section>
        </main>
      )}
    </div>
  );
}

export default OurExpertDetail;