import React from "react";
import ReactDOM from "react-dom";
import "./style.css";
import 'bootstrap-icons/font/bootstrap-icons.css';

import * as serviceWorker from "./serviceWorker";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import {
  Navigation,
  Footer,
  Home,
  About,
  Team,
  Brand,
  Equine,
  Lifestyle,
  HobbyFarming,
  Collectibles,
  Advertising,
  Contact,
  Blog,
  Career,
  JobApply,
  OurExpertDetail,
  Posts,
  Post,
  PrivacyPolicy,
  TermsofService,
} from "./components";

ReactDOM.render(
  <Router>
    <Navigation />
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/about" element={<About />} />
      <Route path="/team" element={<Team />} />
      <Route path="/career" element={<Career />} />
      <Route path="/career/:id" element={<JobApply />} />
      <Route path="/our-expert/:id" element={<OurExpertDetail />} />
      <Route path="/brand" element={<Brand />} />
      <Route path="/brand/equine" element={<Equine />} />
      <Route path="/brand/lifestyle" element={<Lifestyle />} />
      <Route path="/brand/hobbyfarming" element={<HobbyFarming />} />
      <Route path="/brand/collectibles" element={<Collectibles />} />
      <Route path="/contact" element={<Contact />} />
      <Route path="/advertising" element={<Advertising />} />
      <Route path="/blog" element={<Blog />}>
        <Route path="" element={<Posts />} />
        <Route path=":postSlug" element={<Post />} />
      </Route>
      <Route path="/privacy-policy" element={<PrivacyPolicy />} />
      <Route path="/terms-of-service" element={<TermsofService />} />
    </Routes>
    <Footer />
  </Router>,

  document.getElementById("root")
);

serviceWorker.unregister();
