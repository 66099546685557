import React, { useState, useEffect } from "react";
import axios from "../axios";
import { Link } from "react-router-dom";
import ContentLoader, { Facebook, Instagram } from 'react-content-loader';
import { ScaleLoader } from "react-spinners";
function Career() {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [activeTab, setActiveTab] = useState(1);
  const [scale, setScale] = useState(false);
  // modal box

  //https://devsheet.com/create-tab-component-in-react/
  //https://stackoverflow.com/questions/73462974/how-to-filter-the-table-in-search-tab-by-selecting-the-drop-down?rq=1
  const handleTabClick = (id) => {
    setScale(true);
    setActiveTab(id);
    // Marketing 7 Designer 8 developer 6
    switch (id) {
      case 1:
        // call api for All
        axios.get('/jobs/').then((res) => {
          setData(res.data);
          setScale(false);
        });
        
        break;
      case 2:
        // call api for marketing
        axios.get('/jobs/?category_id=7').then((res) => {
          setData(res.data);
          setScale(false);
        });
        break;
      case 3:
        // call api for designer
        axios.get('/jobs/?category_id=8').then((res) => {
          setData(res.data);
          setScale(false);
        });
        break;
      case 4:
        // call api for developer
        axios.get('/jobs/?category_id=6').then((res) => {
          setData(res.data);
          setScale(false);
        });
        break;

      default:
        axios.get('/jobs/').then((res) => {
          setData(res.data);
          setScale(false);
        });
        
        break;
    }

  };

  const fetchJob = (id) => {
    setLoading(true);
    //return axios.get('/teams').then((res) => setData(res.data));
    axios.get('/jobs').then((res) => {
      setLoading(false);
      //console.log(res);
      setData(res.data);
    });

  };

  useEffect(() => {
    document.title = 'Career';
    fetchJob();
  }, []);


  return (
    <>
      {loading ? (
        <main id="main" data-aos="fade-up" className="scrolled-offset mnheight">
          <Facebook speed={3} />
        </main>
      ) : (
        <main id="main" className="scrolled-offset mnheight">
          <section className="inner-page pt-0">

            <div className="row justify-content-center">
              <img src="https://egmediamailimg.s3.ap-south-1.amazonaws.com/web-storage/EG%20Media_corp_website/images/Work-with-us.jpg" className="img-fluid" alt="career cover image" srcset="" loading="lazy" />
            </div>

            <section id="contact" className="contact">
              <div className="container">
                <div className="row justify-content-center">
                  <div className="col-lg-10 col-md-12 col-sm-12">
                    <div className="row justify-content-center">
                      <div className="col-lg-6">
                        <div className="info-box mb-3 p-3">
                          <div className="row justify-content-between">
                            <div className="col-sm-4">
                              <img src="https://egmediamailimg.s3.ap-south-1.amazonaws.com/web-storage/EG%20Media_corp_website/images/icons/Conducive-Evironment.png" alt="" srcset="" />
                            </div>
                            <div className="col-sm-8" style={{ textAlign: 'left' }} >
                              <h6>Conducive Environment</h6>
                              <label for="" className="col-form-label-sm">Lorem ipsum dolor sit, amet consectetur adipisicing elit. Blanditiis dignissimos quod nihil delectus</label>
                            </div>
                          </div>
                        </div>

                        <div className="info-box mb-3 p-3">
                          <div className="row justify-content-between">
                            <div className="col-sm-4">
                              <img src="https://egmediamailimg.s3.ap-south-1.amazonaws.com/web-storage/EG%20Media_corp_website/images/icons/Healthy-Meals-and-Snacks.png" alt="" srcset="" />
                            </div>
                            <div className="col-sm-8" style={{ textAlign: 'left' }}>
                              <h6>Healty Meals and snacks</h6>
                              <label for="" className="col-form-label-sm">Lorem ipsum dolor sit, amet consectetur adipisicing elit. Blanditiis dignissimos quod nihil delectus</label>
                            </div>
                          </div>
                        </div>

                        <div className="info-box mb-3 p-3">
                          <div className="row justify-content-between">
                            <div className="col-sm-4">
                              <img src="https://egmediamailimg.s3.ap-south-1.amazonaws.com/web-storage/EG%20Media_corp_website/images/icons/Best-Gadgets.png" alt="" srcset="" />
                            </div>
                            <div className="col-sm-8" style={{ textAlign: 'left' }}>
                              <h6>Best Gadgets</h6>
                              <label for="" className="col-form-label-sm">Lorem ipsum dolor sit, amet consectetur adipisicing elit. Blanditiis dignissimos quod nihil delectus</label>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="col-lg-6">
                        <div className="info-box mb-3 p-3">
                          <div className="row justify-content-between">
                            <div className="col-sm-4">
                              <img src="https://egmediamailimg.s3.ap-south-1.amazonaws.com/web-storage/EG%20Media_corp_website/images/icons/Shared-Success.png" alt="" srcset="" />
                            </div>
                            <div className="col-sm-8" style={{ textAlign: 'left' }}>
                              <h6>Shared Success</h6>
                              <label for="" className="col-form-label-sm">Lorem ipsum dolor sit, amet consectetur adipisicing elit. Blanditiis dignissimos quod nihil delectus</label>
                            </div>
                          </div>
                        </div>

                        <div className="info-box mb-3 p-3">
                          <div className="row justify-content-between">
                            <div className="col-sm-4">
                              <img src="https://egmediamailimg.s3.ap-south-1.amazonaws.com/web-storage/EG%20Media_corp_website/images/icons/Medical-Insurance.png" alt="" srcset="" />
                            </div>
                            <div className="col-sm-8" style={{ textAlign: 'left' }}>
                              <h6>Medical-Insurance</h6>
                              <label for="" className="col-form-label-sm">Lorem ipsum dolor sit, amet consectetur adipisicing elit. Blanditiis dignissimos quod nihil delectus</label>
                            </div>
                          </div>
                        </div>

                        <div className="info-box mb-3 p-3">
                          <div className="row justify-content-between">
                            <div className="col-sm-4">
                              <img src="https://egmediamailimg.s3.ap-south-1.amazonaws.com/web-storage/EG%20Media_corp_website/images/icons/Culture-Of-Learning.png" alt="" srcset="" />
                            </div>
                            <div className="col-sm-8" style={{ textAlign: 'left' }}>
                              <h6>Culture-Of-Learning</h6>
                              <label for="" className="col-form-label-sm">Lorem ipsum dolor sit, amet consectetur adipisicing elit. Blanditiis dignissimos quod nihil delectus</label>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>

            <div className="row justify-content-center">
              <img src="https://egmediamailimg.s3.ap-south-1.amazonaws.com/web-storage/EG%20Media_corp_website/images/Work-with-us-2.jpg" className="img-fluid" alt="career cover image" srcset="" loading="lazy" />
            </div>


            <section id="portfolio" className="portfolio">
              <div className="container" data-aos="fade-up">

                <div className="section-title">
                  <h3>Check our <span>Positions</span></h3>
                  <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Cumque iure voluptatum alias tempore? Dolorem, mollitia. Animi, culpa.</p>
                </div>

                <div className="row" data-aos="fade-up" data-aos-delay="100">
                  <div className="col-lg-12 d-flex justify-content-center">
                    <ul id="portfolio-flters">
                      <li data-filter=".filter-app" className={activeTab === 1 ? 'filter-active' : ''} onClick={() => handleTabClick(1)}>All</li>
                      <li data-filter=".filter-app" className={activeTab === 2 ? 'filter-active' : ''} onClick={() => handleTabClick(2)}>Marketing</li>
                      <li data-filter=".filter-card" className={activeTab === 3 ? 'filter-active' : ''} onClick={() => handleTabClick(3)}>Designer</li>
                      <li data-filter=".filter-web" className={activeTab === 4 ? 'filter-active' : ''} onClick={() => handleTabClick(4)}>Developer</li>
                    </ul>
                    
                  </div>
                </div>
                

                <div className="row portfolio-container justify-content-center" data-aos="fade-up" data-aos-delay="200">
                <div className="col-12 text-center">
                {scale ? (<ScaleLoader  color="#106eea" height={20}/>) : ''}
                </div>

                  {data.map((dataObj, index) => {
                    return (
                      <div className="col-lg-10 col-md-12 portfolio-item filter-app">
                        <div className="card card-body open-positions">
                          <div className="row align-items-center">
                            <div className="col-sm-9">
                              <div className="row">
                                <div className="col-sm-12"><h6>{dataObj.title}</h6></div>
                                <div className="col-sm-12"><img src="https://egmediamailimg.s3.ap-south-1.amazonaws.com/web-storage/EG%20Media_corp_website/images/icons/Location.png" alt="" srcset="" width="20" height="20" /> USA</div></div>
                              <label for="" className="col-form-label-sm"><div dangerouslySetInnerHTML={{__html: dataObj.post_content}}/></label>
                            </div>
                            <div className="col-sm-3 text-center">
                              <Link to={"/career/" + dataObj.id} className="btn btn-md btn-apply"> Apply </Link>
                            </div>


                          </div>
                        </div>
                      </div>

                    );
                  })}


                </div>

              </div>
            </section>

          </section>
        </main>
      )}

    </>
  );
}

export default Career;
