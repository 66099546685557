import React, { useState } from "react";

import { NavLink } from "react-router-dom";

function Navigation() {
  const [isNavExpanded, setIsNavExpanded] = useState(false)

  return (
    <header id="header" className="d-flex align-items-center fixed-top">
      <div className="container d-flex align-items-center justify-content-between">

        <NavLink className="logo" to="/">
          <img src="https://egmediamailimg.s3.ap-south-1.amazonaws.com/web-storage/EG%20Media_corp_website/Logo/EG-Logo.png" alt="" />
        </NavLink>

        <nav id="navbar" className={
          isNavExpanded ? "navbar navbar-mobile" : "navbar"
        } >
          <ul>
            <li className="nav-item">
              <NavLink className="nav-link" to="/">
                Home
                <span className="sr-only">(current)</span>
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink className="nav-link" to="/about">
                About
              </NavLink>
            </li>
 
            <li className="dropdown">
              <a className="dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" href="#">Brands
                <span className="caret"></span></a>

              <ul className="dropdown-menu submenu">

                <li className="nav-item">
                  <NavLink className="nav-link dd" to="/brand/equine">
                    Equine
                  </NavLink>
                </li>

                <li className="nav-item">
                  <NavLink className="nav-link dd" to="/brand/lifestyle">
                    Lifestyle
                  </NavLink>
                </li>

                <li className="nav-item">
                  <NavLink className="nav-link dd" to="/brand/hobbyfarming">
                    Hobby Farming
                  </NavLink>
                </li>

                <li className="nav-item">
                  <NavLink className="nav-link dd" to="/brand/collectibles">
                    Collectibles
                  </NavLink>
                </li>


              </ul>
            </li>

            <li className="nav-item">
              <NavLink className="nav-link" to="/advertising">
                Advertising
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink className="nav-link" to="/career">
                Career
              </NavLink>
            </li>

            <li className="nav-item">
              <NavLink className="nav-link" to="/team">
                Team
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink className="nav-link" to="/contact">
                Contact Us
              </NavLink>
            </li>

          </ul>

          <i className={
          isNavExpanded ? "bi mobile-nav-toggle bi-x" : "bi bi-list mobile-nav-toggle"
        } onClick={() => {
          setIsNavExpanded(!isNavExpanded)
        }}></i>
 
        </nav>

      </div>
    </header>
  );
}

export default Navigation;
