import React, { useState, useEffect } from "react";
import axios from "../axios";
import Swal from 'sweetalert2';
import { useForm } from "react-hook-form";
import { Button, Spinner } from 'react-bootstrap';
import ContentLoader, { Facebook, Instagram } from 'react-content-loader';

function Contact() {

  const { register, handleSubmit, reset, formState: { errors } } = useForm({
    defaultValues: {
      name: "",
      email: "",
      subject: "",
      message: "",
    },
  });

  const [loading, setLoading] = useState(false);

  const [loadingbtn, setLoadingbtn] = useState(false);

  useEffect(() => {
    document.title = 'Contact';
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 300);
  }, []);


  const onSubmit = (data) => {
    //console.log(data);
    //setLoading(false);
    setLoadingbtn(true);

    axios.post('/contact', {
      name: data.name,
      email: data.email,
      subject: data.subject,
      message: data.message
    })
      .then(function (response) {
        Swal.fire({
          icon: 'success',
          title: 'Your message has been sent. Thank you!',
          showConfirmButton: false,
          timer: 1500
        })
        setLoading(false);
        setLoadingbtn(false);
        reset();
      })
      .catch(function (error) {
        Swal.fire({
          icon: 'error',
          title: 'An Error Occured!',
          showConfirmButton: false,
          timer: 1500
        })
        setLoading(false)
      });

  }

  return (
    <>

      {loading ? (
        <main id="main" data-aos="fade-up" className="scrolled-offset mnheight">
          <Facebook speed={3} />
        </main>
      ) : (
        <main id="main" className="scrolled-offset mnheight">
          <section class="inner-page pt-0">
            <div class="row justify-content-center">
              <img src="https://egmediamailimg.s3.ap-south-1.amazonaws.com/web-storage/EG%20Media_corp_website/images/Contact-us.jpg" class="img-fluid" alt="contact us cover image" srcset="" loading="lazy" />
            </div>

            <section id="contact" class="contact">
              <div class="container">
                <div class="row justify-content-center">
                  <div class="col-lg-12 col-md-12 col-sm-12">
                    <div class="row justify-content-center">
                      <div class="col-lg-4">
                        <div class="card card-body mb-3">
                          <div class="row justify-content-between">
                            <div class="col-sm-4">
                              <img src="https://egmediamailimg.s3.ap-south-1.amazonaws.com/web-storage/EG%20Media_corp_website/images/icons/Phone-Number.png" alt="" srcset="" />
                            </div>
                            <div class="col-sm-8">
                              <h6>Phone Number</h6>
                              <label for="">+0123456789</label>
                            </div>
                          </div>
                        </div>

                        <div class="card card-body mb-3">
                          <div class="row justify-content-between">
                            <div class="col-sm-4">
                              <img src="https://egmediamailimg.s3.ap-south-1.amazonaws.com/web-storage/EG%20Media_corp_website/images/icons/Email-Address.png" alt="" srcset="" />
                            </div>
                            <div class="col-sm-8">
                              <h6>Email Address</h6>
                              <label for="">abcd12345@test.com</label>
                            </div>
                          </div>
                        </div>


                        <div class="card card-body mb-3">
                          <div class="row justify-content-between">
                            <div class="col-sm-4">
                              <img src="https://egmediamailimg.s3.ap-south-1.amazonaws.com/web-storage/EG%20Media_corp_website/images/icons/Location.png" alt="" srcset="" />
                            </div>
                            <div class="col-sm-8">
                              <h6>Location</h6>
                              <label for="">3622 Lyckan Parkway, Suite 3003, Durham, North Carolina 27707</label>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="col-lg-8">
                        <div class="card card-body">
                          <h3>Send Message</h3>
                          <label for="" class="col-form-label-sm">Lorem, ipsum dolor sit amet consectetur adipisicing elit. Harum, reprehenderit voluptates quod inventore eaque explicabo iste facere dicta excepturi.</label>
                          <form onSubmit={handleSubmit(onSubmit)}>

                            <div className="row">
                              <div className="col form-group">
                                <input
                                  type="text"
                                  className="form-control"
                                  id="name"
                                  name="name" placeholder="Your Name" {...register("name", {
                                    required: "Required",
                                  })} />
                                {errors.name && <p style={{ color: 'red', fontSize: '.875em' }}>Please enter the name</p>}
                              </div>

                              <div className="col form-group">
                                <input
                                  type="email"
                                  className="form-control"
                                  id="email"
                                  name="email" placeholder="Your Email" {...register("email", {
                                    required: "Please enter the email",
                                    pattern: {
                                      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                      message: "invalid email address"
                                    }
                                  })} />
                                {errors.email && <p style={{ color: 'red', fontSize: '.875em' }}>{errors.email.message}</p>}
                              </div>

                            </div>

                            <div className="form-group">
                              <input type="text" className="form-control" name="subject" id="subject" placeholder="Subject" {...register("subject", {
                                required: "Required",
                              })} />
                              {errors.name && <p style={{ color: 'red', fontSize: '.875em' }}>Please enter the subject</p>}
                            </div>

                            <div className="form-group">
                              <textarea
                                className="form-control"
                                id="message"
                                rows="3"
                                name="message" placeholder="Message" {...register("message", {
                                  required: "Required",
                                })}></textarea>
                              {errors.message && <p style={{ color: 'red', fontSize: '.875em' }}>Please enter the message</p>}
                            </div>

                            <div class="text-left">

                              {loadingbtn ? <Button variant="primary" disabled><Spinner as="span" variant="warning" size="sm" role="status" aria-hidden="true" animation="grow" />Loading...</Button> : <Button type="submit">Submit</Button>}

                            </div>

                          </form>

                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="row justify-content-center bg-light mb-4">
                  <div class="section-title">
                    <h3><span>Find Us on Google Maps</span></h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Iusto exercitationem nulla consequatur officiis reiciendis, animi, repellat.</p>
                  </div>

                  <div class="col-lg-10 col-md-12 col-sm-12">
                    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3229.290487854582!2d-78.9651948253319!3d35.964322914373!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89ace65a00303239%3A0x36ef845a7e73a835!2s3622%20Lyckan%20Pkwy%20Suite%203003%2C%20Durham%2C%20NC%2027707%2C%20USA!5e0!3m2!1sen!2sin!4v1684759598312!5m2!1sen!2sin" height="385" style={{ border: '0', width: '100%' }} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                  </div>
                </div>

              </div>
            </section>

          </section>
        </main>
      )}

    </>
  );
}

export default Contact;
