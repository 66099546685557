import React, { useState, useEffect } from "react";
import axios from "../axios";

function Brand() {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const fetchInfo = () => {
    setLoading(true);
    //return axios.get('/teams').then((res) => setData(res.data));
    axios.get('/brands').then((res) => {
      setLoading(false);
      //console.log(res);
      setData(res.data);
    });

  };

  useEffect(() => {
    document.title = 'Brand';
    fetchInfo();
  }, []);

  return (
    <main id="main" data-aos="fade-up" className="scrolled-offset mnheight">

      {loading ? (
        <div className="loader-container">
      	  <div className="spinner"></div>
        </div>
      ) : (

        <section className="inner-page">
        <div className="container">
          <div className="row p-3">
            <div className="col-lg-12 col-md-12 col-sm-12">
              <h2 className="headline">equine</h2>
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="col-lg-10">
  
  
            {data.map((dataObj, index) => {
            return (
          
              <div className="row justify-content-center mb-5">
              <div className="col-lg-4 col-md-6 col-sm-12 text-center">
                <img src={dataObj.img} alt="horse illustrated" className="img-fluid img-responsive" style={{width:"250px"}} />
              </div>
              <div className="col-lg-8 col-md-6 col-sm-12">
                <div className="brand-logo">
                  <img src={dataObj.website_logo} alt="horse illustrated" className="img-fluid img-responsive" style={{width:"150px"}} />
                </div>
                <div className="desc-text">
                  <p className="m-5 mt-3">{dataObj.post_content}</p>
                </div>
                <div className="btn-rm">
                  <a href={dataObj.website_url} target="_blank"  className="btn btn-md btn-light shadow-sm m-5 mt-1">Read More <span> <i className="bi bi-caret-right-fill"></i> </span></a>
                </div>
              </div>
            </div>
 
            );
          })}
  
            </div>
          </div>
        </div>
      </section>

        )}

  </main> 
  );
}

export default Brand;
