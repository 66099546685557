import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import axios from "../axios";
import Swal from 'sweetalert2';
import { useForm } from "react-hook-form";
import { Button, Spinner } from 'react-bootstrap';
import ContentLoader, { Facebook, Instagram } from 'react-content-loader';
function JobApply() {
  const [loadingbtn, setLoadingbtn] = useState(false);
  const [loading, setLoading] = useState(true);
  // Get ID from URL
  const params = useParams();
  const jobids = params.id;
  const [posts, setPosts] = useState([]);

  const [file, setFile] = useState();
  const [uploadedFile, setUploadedFile] = useState();
  const [error, setError] = useState();
  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [subject, setSubject] = useState('')
  const [phone, setPhone] = useState('')
  const [message, setMessage] = useState('')
  
  const [errors, setErrors] = useState({
    name: '',
    email: '',
    phone: '',
    subject: '',
    file: '',
    message: '',
});
  

  function handleChange(event) {
    setFile(event.target.files[0]);
  }

  function handleSubmit(event) {
    event.preventDefault();

   // console.log(validateForm());
   if(validateForm()){
    //const employee = {firstName, lastName, email}
    //console.log(employee) 

    const formData = new FormData();
    formData.append('file', file);
    formData.append('fileName', file.name);
    formData.append('name', name);
    formData.append('email', email);
    formData.append('subject', subject);
    formData.append('phone', phone);
    formData.append('message', message);
    formData.append('jobid', jobids);
    
    const config = {
      headers: {
        'content-type': 'multipart/form-data',
      },
    };
    axios.post('/job_apply', formData, config)
      .then((response) => {
        //console.log(response.data);
        //setUploadedFile(response.data.file);

        Swal.fire({
          icon: 'success',
          title: 'Your message has been sent. Thank you!',
          showConfirmButton: false,
          timer: 1500
        })
        setLoading(false);
        setLoadingbtn(false);
        //reset();
        //event.target.reset();
        setName("");
        setEmail("");
        setSubject("");
        setPhone("");
        setMessage("");
        setFile("");

      })
      .catch((error) => {
       // console.error("Error uploading file: ", error);
        //setError(error);
        Swal.fire({
          icon: 'error',
          title: 'An Error Occured!',
          showConfirmButton: false,
          timer: 1500
        })
        setLoading(false)

      });
  }

}
    

  useEffect(() => {
    document.title = 'Career';
    axios.get('/post_detail/?post_id=' + params.id).then((res) => {
      setPosts(res.data);
    });

    setLoading(false);

  }, []);

const validateForm = () => {
        let valid = true;
        //const { name, email, password } = formData;
        const errorsCopy = { ...errors };
    
        if (!name.trim()) {
          errorsCopy.name = 'Name is required';
          valid = false;
        } else {
          errorsCopy.name = '';
        }
    
        if (!email.trim()) {
          errorsCopy.email = 'Email is required';
          valid = false;
        } else if (!/\S+@\S+\.\S+/.test(email)) {
          errorsCopy.email = 'Email is invalid';
          valid = false;
        } else {
          errorsCopy.email = '';
        }

        if (!subject.trim()) {
          errorsCopy.subject = 'Subject is required';
          valid = false;
        } else {
          errorsCopy.subject = '';
        }

        if (!phone.trim()) {
          errorsCopy.phone = 'Phone is required';
          valid = false;
        } else {
          errorsCopy.phone = '';
        }

        if (!message.trim()) {
          errorsCopy.message = 'Message is required';
          valid = false;
        } else {
          errorsCopy.message = '';
        }

        if (!file) {
          errorsCopy.file = 'Resume is required';
          valid = false;
        } else {
          errorsCopy.file = '';
        }

 
        setErrors(errorsCopy);
        return valid;
      };


  return (

    <div>

      {loading ? (
        <main id="main" data-aos="fade-up" className="scrolled-offset mnheight">
          <Facebook speed={3} />
        </main>
      ) : (
        <main id="main" className="scrolled-offset mnheight">
          <section className="inner-page pt-0">
            <div className="row">
              <img src="https://egmediamailimg.s3.ap-south-1.amazonaws.com/web-storage/EG%20Media_corp_website/images/Contact-us.jpg" className="img-fluid" alt="contact us cover image" srcset="" loading="lazy" />
            </div>

            <section id="contact" className="contact">
              <div className="container">
                <div className="row justify-content-center">
                  <div className="col-lg-10 col-md-12 col-sm-12">
                    <div className="row justify-content-center">


                      <div className="col-lg-12">
                        <h1>{posts.title}</h1>
                        <p><div dangerouslySetInnerHTML={{ __html: posts.post_content }} /> </p>
                      </div>

                      <div className="col-lg-12">
                        <div className="card card-body">
                          <h3>Apply Job</h3>
                          <form onSubmit={handleSubmit}>
 
                            <div className="row">
                              <div className="col form-group">
                                <input
                                  type="text"
                                   
                                  id="name"
                                  name="name" className={`form-control ${errors.name ? 'is-invalid' : ''}`} placeholder="Your Name" value={name}
                                  onChange={(e) => setName(e.target.value)} />
                              </div>

                              <div className="col form-group">
                                <input
                                  type="text"
                                  className={`form-control ${errors.email ? 'is-invalid' : ''}`}
                                  id="email"
                                  name="email" placeholder="Your Email" value={email}
                                  onChange={(e) => setEmail(e.target.value)} />

                              </div>

                            </div>


                            <div className="row">
                              <div className="col form-group">
                                <input type="text" className={`form-control ${errors.subject ? 'is-invalid' : ''}`} name="subject" id="subject" placeholder="Subject" value={subject}
                                  onChange={(e) => setSubject(e.target.value)}/>
                               </div>

                              <div className="col form-group">
                                <input type="number" className={`form-control ${errors.phone ? 'is-invalid' : ''}`} name="phone" id="phone" placeholder="Phone"  value={phone}
                                  onChange={(e) => setPhone(e.target.value)}/>
                               </div>

                               <div className="col form-group">
                                <input type="file"  accept='.doc,.docx,application/pdf' className={`form-control ${errors.file ? 'is-invalid' : ''}`} name="file" id="file" onChange={handleChange} />
                               </div>

                            </div>

                            <div className="form-group">
                              <textarea
                               className={`form-control ${errors.message ? 'is-invalid' : ''}`}
                                id="message"
                                rows="3"
                                name="message" placeholder="Message"
                                onChange={(e) => setMessage(e.target.value)}>{message}</textarea>
                             
                            </div>
                            
                            {loadingbtn ? <Button variant="primary" disabled><Spinner as="span" variant="warning" size="sm" role="status" aria-hidden="true" animation="grow" />Loading...</Button> : <Button type="submit">Apply Job</Button>}
                          </form>

                        </div>
                      </div>
                    </div>
                  </div>
                </div>



              </div>
            </section>

          </section>
        </main>
      )}
    </div>
  );
}

export default JobApply;

